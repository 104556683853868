import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { LayoutService, AppConfig } from './layout/service/app.layout.service';
import { MenuService } from './layout/app.menu.service';
import { ActivatedRoute } from '@angular/router';
import { LocalStoreService } from './LocalStoreService/local-store.service';
import _ from "lodash";
import { fromEvent, interval } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StorageService } from './_services/storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    login: any   = '';
    private TempoInatividade = 7200000;
    private reiniciarTempo: any;

    constructor(private primengConfig: PrimeNGConfig, private layoutService: LayoutService, private menuService: MenuService,
        private ls: LocalStoreService,
        private route: ActivatedRoute,
        private router: Router,
        private storageService: StorageService
    ) {
        fromEvent(document, 'mousemove').pipe(
            debounceTime(100)
          ).subscribe(() => {
            this.resetarTempo();
          });

        fromEvent(document, 'keypress')
        .pipe(debounceTime(1000))
        .subscribe(() => this.resetarTempo());

        this.inciarTempo();
     }

    ngOnInit(): void {

        //optional configuration with the default configuration
        const config: AppConfig = {
            ripple: true,                      //toggles ripple on and off
            inputStyle: 'filled',             //default style for input elements
            menuMode: 'slim-plus',                 //layout mode of the menu, valid values are "static", "overlay", "slim", "horizontal", "reveal" and "drawer" 
            colorScheme: 'light',               //color scheme of the template, valid values are "light", "dim" and "dark"
            theme: 'orange',                    //default component theme for PrimeNG
            menuTheme: "transparent",           //theme of the menu, valid values are "colorScheme", "primaryColor" and "transparent"
            scale: 13                          //size of the body font size to scale the whole application
        };
        this.layoutService.config.set(config);
    }

    private inciarTempo(): void {
       /*  this.reiniciarTempo = setTimeout(() => {
                this.storageService.logout();
                this.router.navigate(['/login']);
            }, 
            this.TempoInatividade
        ); */
    }
    
    private resetarTempo() {
        clearTimeout(this.reiniciarTempo);
        this.inciarTempo();
    }

}