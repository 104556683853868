import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AppService {

    private apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }
    
    getLoginGap(chave: number) {
        return this.http.get<any>(`${this.apiUrl}/login/gap?chave=${chave}`)
                        .toPromise()
                        .then(res => res);             
     }
}
