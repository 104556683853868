import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { StorageService } from '../_services/storage.service';
import { Router } from '@angular/router';
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent {

    @ViewChild('menubutton') menuButton!: ElementRef;

    constructor(public layoutService: LayoutService,
                        private storageService: StorageService,
                        private router: Router,
    ) { }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onProfileButtonClick() {
        this.layoutService.showProfileSidebar();
    }
    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }
    
    MobileView(): boolean {
        return window.innerWidth <= 768; 
      }

      signOut(){
        this.layoutService.fecharProfileSidebar();
        this.storageService.logout();
        this.router.navigate(['/login']);
    }
}