import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,  Router} from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { LocalStoreService } from './LocalStoreService/local-store.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import _ from "lodash";
import { StorageService } from './_services/storage.service';
import { AppService } from './app.service';
import { LayoutService, AppConfig } from './layout/service/app.layout.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {

  constructor(
    private ls: LocalStoreService,
    private router: Router,
    private storageService: StorageService,
    private appService: AppService,
    private layoutService: LayoutService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    //verificacaogap
    const rota = next.routeConfig?.path;
    if (rota?.split('/')[0] == 'gap') 
    {
      window.sessionStorage.clear();
      window.localStorage.clear();
      window.sessionStorage.removeItem('auth-user');

      return this.appService
      .getLoginGap(next.params['codigo'])
      .then((response) => {

        this.ls.setItem('permissoes',     response.permissoes);
        this.ls.setItem('perfil',         response.perfil);
        this.ls.setItem('agente',         response.agente);
        this.ls.setItem('unidade',        response.unidade); 
        this.ls.setItem('token',          response.token);
        this.ls.setItem('refresh_token',  response.refresh_token);
        this.storageService.saveUser(response);

          const config: AppConfig = {
            ripple: true,                     
            inputStyle: 'filled',             
            menuMode: 'overlay',             
            colorScheme: 'light',               
            theme: 'blue',                    
            menuTheme: "colorScheme",           
            scale: 13                           
        };
        this.layoutService.config.set(config);

        return true;
      },
      (error) =>{
        this.router.navigate(['/unauthorized']);
        return false;
      });
    } else
    {
      if(!this.storageService.isLoggedIn())
        {
          this.router.navigate(['/login']);
          return false;
        }
    
        const routeName = next.routeConfig?.path;
        if (routeName && this.ls.findMenu(routeName)) {
          return true;
        } else
        {
          this.router.navigate(['/unauthorized']);
          return false;
        }
    }
  }
}

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
};

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,

    children: [
      //INSS
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      {
        path: 'consultainss',
        data: { breadcrumb: 'Consulta INSS' },
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('./pages/GInss/consultainss/consultarInss.module').then(
            (m) => m.ConsultarInssModule
          ),
      },
      {
        path: 'inssconfirmada',
        data: { breadcrumb: 'Digitacação INSS' },
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('./pages/GInss/inssconfirmada/inssconfirmada.module').then(
            (m) => m.InssconfirmadaModule
          ),
      },
      {
        path: 'simulacaoinss',
        data: { breadcrumb: 'Tabelas' },
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('./pages/GInss/simulacaoinss/simulacaoinss.module').then(
            (m) => m.SimulacaoInssModule
          ),
      },
      {
        path: 'roteiro',
        data: { breadcrumb: 'Roteiros' },
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('./pages/GInss/roteiro/roteiro.module').then(
            (m) => m.RoteiroModule
          ),
      },
      {
        path: 'saldoextrato',
        data: { breadcrumb: 'Saldo Extrato' },
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('./pages/GInss/saldoextrato/saldoextrato.module').then(
            (m) => m.SaldoextratoModule
          ),
      },
      {
        path: 'propostas',
        data: { breadcrumb: 'Proposta' },
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('./pages/GEsteira/propostas/proposta.module').then(
            (m) => m.PropostaModule
          ),
      },
      //FINANCEIRO
       {
        path: 'financeiraproposta',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Financeira Proposta' },
        loadChildren: () =>
          import('./pages/GFinanceiro/financeiraproposta/financeiraproposta.module').then(
            (m) => m.FinanceiraPropostaModule
          ),
      },
      {
        path: 'unidadenegocios',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Unidade de Negócios' },
        loadChildren: () =>
          import('./pages/GFinanceiro/unidade/unidadenegocios.module').then(
            (m) => m.UnidadeNegociosModule
          ),
      },
      {
        path: 'unidadenivel',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Nivel de Unidade' },
        loadChildren: () =>
          import('./pages/GFinanceiro/unidadenivel/unidadenivel.module').then(
            (m) => m.UnidadeNivelModule
          ),
      },
      {
        path: 'esteira',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Esteira' },
        loadChildren: () =>
          import('./pages/GFinanceiro/esteira/esteira.module').then(
            (m) => m.EsteiraModule
          ),
      },
      {
        path: 'banco',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Banco' },
        loadChildren: () =>
          import('./pages/GFinanceiro/banco/banco.module').then(
            (m) => m.BancoModule
          ),
      },
      {
        path: 'permissao',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Permissao' },
        loadChildren: () =>
          import('./pages/GFinanceiro/permissao/permissao.module').then(
            (m) => m.PermissaoModule
          ),
      },
      {
        path: 'politicadepreco',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Política de Preço' },
        loadChildren: () =>
          import('./pages/GFinanceiro/politicapreco/unidadepoliticapreco.module').then(
            (m) => m.UnidadePoliticaPrecoModule
          ),
      },
      {
        path: 'tabelacomissao',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Tabela de Comissão' },
        loadChildren: () =>
          import('./pages/GFinanceiro/tabelacomissao/tabelacomissao.module').then(
            (m) => m.TabelaComissaoModule
          ),
      },
      {
        path: 'tabelacomercial',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Tabela Comercial' },
        loadChildren: () =>
          import('./pages/GFinanceiro/tabelacomercial/tabelacomercial.module').then(
            (m) => m.TabelaComercialModule
          ),
      },
      {
        path: 'contacorrente',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Conta Corrente' },
        loadChildren: () =>
          import('./pages/GFinanceiro/contacorrente/contacorrente.module').then(
            (m) => m.ContaCorrenteModule
          ) 
      },
      {
        path: 'financeiraunidade',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Financeira Unidade' },
        loadChildren: () =>
          import('./pages/GFinanceiro/financeiraunidade/financeiraunidade.module').then(
            (m) => m.FinanceiraUnidadeModule
          ) 
      },
      {
        path: 'fechamento',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Fechamento' },
        loadChildren: () =>
          import('./pages/GFinanceiro/fechamento/fechamento.module').then(
            (m) => m.FechamentoModule
          ) 
      },
      {
        path: 'historicofechamento',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Historico Fechamento' },
        loadChildren: () =>
          import('./pages/GFinanceiro/historicofechamento/historicofechamento.module').then(
            (m) => m.HistoricoFechamentoModule
          ) 
      },
      {
        path: 'promotora',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Promotora' },
        loadChildren: () =>
          import('./pages/GFinanceiro/promotora/promotora.module').then(
            (m) => m.PromotoraModule
          ) 
      },
      {
        path: 'convenio',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Convenio' },
        loadChildren: () =>
          import('./pages/GFinanceiro/convenio/convenio.module').then(
            (m) => m.ConvenioModule
          ) 
      },
      {
        path: 'formacontrato',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Forma de Contrato' },
        loadChildren: () =>
          import('./pages/GFinanceiro/formacontrato/formacontrato.module').then(
            (m) => m.FormaContratoModule
          ) 
      },
      {
        path: 'financeiracia',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Financeira Cia' },
        loadChildren: () =>
          import('./pages/GFinanceiro/financeiracia/financeiracia.module').then(
            (m) => m.FinanceiraModule
          ) 
      },
      {
        path: 'spread',
        data: { breadcrumb: 'Spread' },
        loadChildren: () =>
          import('./pages/GFinanceiro/spread/spread.module').then((m) => m.SpreadModule),
      }, 
      //FGTS
      {
        path: 'tabelasfgts',
        data: { breadcrumb: 'Tabelas Fgts' },
        loadChildren: () =>
          import('./pages/GFgts/tabelafgts/tabelafgts.module').then((m) => m.TabelaFgtsModule),
      }, 
      {
        path: 'consultafgts',
        data: { breadcrumb: 'Consulta Fgts' },
        loadChildren: () =>
          import('./pages/GFgts/consultafgts/consultafgts.module').then((m) => m.ConsultaFgtsModule),
      }, 
      {
        path: 'esteirafgts',
        data: { breadcrumb: 'Esteira Fgts' },
        loadChildren: () =>
          import('./pages/GFgts/esteirafgts/esteirafgts.module').then((m) => m.EsteiraFgtsModule),
      }, 
      //SUPORTE
      {
        path: 'cofresenha',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Cofre de Senha' },
        loadChildren: () =>
          import('./pages/GSuporte/cofresenha/cofresenha.module').then(
            (m) => m.CofreSenhaModule
          ),
      },
      {
        path: 'statusproposta',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Status Proposta' },
        loadChildren: () =>
          import('./pages/GSuporte/statusproposta/statusproposta.module').then(
            (m) => m.StatusPropostaModule
          ),
      },
      {
        path: 'motivo',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Motivo' },
        loadChildren: () =>
          import('./pages/GSuporte/motivo/motivo.module').then(
            (m) => m.MotivoModule
          ),
      },
      {
        path: 'tipodocumento',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Tipos de Documento' },
        loadChildren: () =>
          import('./pages/GSuporte/arquivotipo/arquivotipo.module').then(
            (m) => m.ArquivoTipoModule
          ),
      },
      {
        path: 'conta',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Conta' },
        loadChildren: () =>
          import('./pages/GFinanceiro/conta/conta.module').then(
            (m) => m.ContaModule
          ),
      },
      {
        path: 'formapagamento',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Forma de Pagamento' },
        loadChildren: () =>
          import('./pages/GFinanceiro/formapagamento/formapagamento.module').then(
            (m) => m.FormaPagamentoModule
          ),
      },
      //IMPORTACAO
      {
        path: 'campo',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Campo' },
        loadChildren: () =>
          import('./pages/GImportacao/campo/campo.module').then((m) => m.CampoModule),
      },
      {
        path: 'layout',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Layout' },
        loadChildren: () =>
          import('./pages/GImportacao/layout/layout.module').then((m) => m.LayoutModule),
      },
      {
        path: 'statusapi',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Status' },
        loadChildren: () =>
          import('./pages/GImportacao/status_api/status_api.module').then(
            (m) => m.StatusApiModule
          ),
      },
      {
        path: 'convenioapi',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Convênio API' },
        loadChildren: () =>
          import('./pages/GImportacao/convenio_api/convenio_api.module').then(
            (m) => m.ConvenioApiModule
          ),
      },
      {
        path: 'comissaoapi',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Comissão' },
        loadChildren: () =>
          import('./pages/GImportacao/comissao/comissao.module').then(
            (m) => m.ComissaoModule
          ),
      },
      {
        path: 'formacontratoapi',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Forma de Contrato API' },
        loadChildren: () =>
          import('./pages/GImportacao/formadecontrato_api/formadecontrato_api.module').then(
            (m) => m.FormadecontratoApiModule
          ),
      },
      {
        path: 'finalidadeapi',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Finalidade' },
        loadChildren: () =>
          import('./pages/GImportacao/finalidade_api/finalidade_api.module').then(
            (m) => m.FinalidadeApiModule
          ),
      },
      {
        path: 'financeiraapi',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Financeiras' },
        loadChildren: () =>
          import('./pages/GImportacao/financeiracia_api/financeiracia_api.module').then(
            (m) => m.FinanceiraciaApiModule
          ),
      },
      {
        path: 'usuarioapi',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Usuário' },
        loadChildren: () =>
          import('./pages/GImportacao/usuario_api/usuario_api.module').then(
            (m) => m.UsuarioApiModule
          ),
      },
      {
        path: 'bancoapi',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Banco Api' },
        loadChildren: () =>
          import('./pages/GImportacao/bancoapi/bancoapi.module').then(
            (m) => m.BancoApiModule
          ),
      },
      {
        path: 'motivoapi',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Motivo API' },
        loadChildren: () =>
          import('./pages/GImportacao/motivoapi/motivoapi.module').then(
            (m) => m.MotivoApiModule
          ),
      },
      {
        path: 'importacao',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Importação' },
        loadChildren: () =>
          import('./pages/importacao/importacao.module').then(
            (m) => m.ImportacaoModule
          ),
      },
      // ROTAS DO GAP
      {
        path: 'gap/:codigo/consultainss',
        data: { breadcrumb: 'Consulta INSS' },
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('./pages/GInss/consultainss/consultarInss.module').then(
            (m) => m.ConsultarInssModule
          ),
      },
      {
        path: 'gap/:codigo/inssconfirmada',
        data: { breadcrumb: 'Digitacação INSS' },
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('./pages/GInss/inssconfirmada/inssconfirmada.module').then(
            (m) => m.InssconfirmadaModule
          ),
      },
      {
        path: 'gap/:codigo/simulacaoinss',
        data: { breadcrumb: 'Tabelas' },
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('./pages/GInss/simulacaoinss/simulacaoinss.module').then(
            (m) => m.SimulacaoInssModule
          ),
      },
      {
        path: 'gap/:codigo/roteiro',
        data: { breadcrumb: 'Roteiros' },
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('./pages/GInss/roteiro/roteiro.module').then(
            (m) => m.RoteiroModule
          ),
      },
      {
        path: 'gap/:codigo/saldoextrato',
        data: { breadcrumb: 'Saldo Extrato' },
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('./pages/GInss/saldoextrato/saldoextrato.module').then(
            (m) => m.SaldoextratoModule
          ),
      },
      {
        path: 'gap/:codigo/propostas',
        data: { breadcrumb: 'Proposta' },
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('./pages/GEsteira/propostas/proposta.module').then(
            (m) => m.PropostaModule
          ),
      },
      //FINANCEIRO
       {
        path: 'gap/:codigo/financeiraproposta',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Financeira Proposta' },
        loadChildren: () =>
          import('./pages/GFinanceiro/financeiraproposta/financeiraproposta.module').then(
            (m) => m.FinanceiraPropostaModule
          ),
      },
      {
        path: 'gap/:codigo/unidadenegocios',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Unidade de Negócios' },
        loadChildren: () =>
          import('./pages/GFinanceiro/unidade/unidadenegocios.module').then(
            (m) => m.UnidadeNegociosModule
          ),
      },
      {
        path: 'gap/:codigo/politicadepreco',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Política de Preço' },
        loadChildren: () =>
          import('./pages/GFinanceiro/politicapreco/unidadepoliticapreco.module').then(
            (m) => m.UnidadePoliticaPrecoModule
          ),
      },
      {
        path: 'gap/:codigo/tabelacomissao',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Tabela de Comissão' },
        loadChildren: () =>
          import('./pages/GFinanceiro/tabelacomissao/tabelacomissao.module').then(
            (m) => m.TabelaComissaoModule
          ),
      },
      {
        path: 'gap/:codigo/tabelacomercial',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Tabela Comercial' },
        loadChildren: () =>
          import('./pages/GFinanceiro/tabelacomercial/tabelacomercial.module').then(
            (m) => m.TabelaComercialModule
          ),
      },
      {
        path: 'gap/:codigo/contacorrente',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Conta Corrente' },
        loadChildren: () =>
          import('./pages/GFinanceiro/contacorrente/contacorrente.module').then(
            (m) => m.ContaCorrenteModule
          ) 
      },
      {
        path: 'gap/:codigo/financeiraunidade',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Financeira Unidade' },
        loadChildren: () =>
          import('./pages/GFinanceiro/financeiraunidade/financeiraunidade.module').then(
            (m) => m.FinanceiraUnidadeModule
          ) 
      },
      {
        path: 'gap/:codigo/fechamento',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Fechamento' },
        loadChildren: () =>
          import('./pages/GFinanceiro/fechamento/fechamento.module').then(
            (m) => m.FechamentoModule
          ) 
      },
      {
        path: 'gap/:codigo/historicofechamento',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Fechamento' },
        loadChildren: () =>
          import('./pages/GFinanceiro/historicofechamento/historicofechamento.module').then(
            (m) => m.HistoricoFechamentoModule
          ) 
      },
      {
        path: 'gap/:codigo/promotora',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Promotora' },
        loadChildren: () =>
          import('./pages/GFinanceiro/promotora/promotora.module').then(
            (m) => m.PromotoraModule
          ) 
      },
      {
        path: 'gap/:codigo/convenio',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Convenio' },
        loadChildren: () =>
          import('./pages/GFinanceiro/convenio/convenio.module').then(
            (m) => m.ConvenioModule
          ) 
      },
      {
        path: 'gap/:codigo/formacontrato',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Forma de Contrato' },
        loadChildren: () =>
          import('./pages/GFinanceiro/formacontrato/formacontrato.module').then(
            (m) => m.FormaContratoModule
          ) 
      },
      {
        path: 'gap/:codigo/financeiracia',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Financeira Cia' },
        loadChildren: () =>
          import('./pages/GFinanceiro/financeiracia/financeiracia.module').then(
            (m) => m.FinanceiraModule
          ) 
      },
      {
        path: 'gap/:codigo/spread',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Spread' },
        loadChildren: () =>
          import('./pages/GFinanceiro/spread/spread.module').then((m) => m.SpreadModule),
      }, 
      {
        path: 'gap/:codigo/unidadenivel',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Nivel de Unidade' },
        loadChildren: () =>
          import('./pages/GFinanceiro/unidadenivel/unidadenivel.module').then(
            (m) => m.UnidadeNivelModule
          ),
      },
      {
        path: 'gap/:codigo/esteira',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Esteira' },
        loadChildren: () =>
          import('./pages/GFinanceiro/esteira/esteira.module').then(
            (m) => m.EsteiraModule
          ),
      },
      {
        path: 'gap/:codigo/banco',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Banco' },
        loadChildren: () =>
          import('./pages/GFinanceiro/banco/banco.module').then(
            (m) => m.BancoModule
          ),
      },
      {
        path: 'gap/:codigo/permissao',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Permissao' },
        loadChildren: () =>
          import('./pages/GFinanceiro/permissao/permissao.module').then(
            (m) => m.PermissaoModule
          ),
      },
      //FGTS
      {
        path: 'gap/:codigo/tabelasfgts',
        data: { breadcrumb: 'Tabelas Fgts' },
        loadChildren: () =>
          import('./pages/GFgts/tabelafgts/tabelafgts.module').then((m) => m.TabelaFgtsModule),
      }, 
      {
        path: 'gap/:codigo/consultafgts',
        data: { breadcrumb: 'Consulta Fgts' },
        loadChildren: () =>
          import('./pages/GFgts/consultafgts/consultafgts.module').then((m) => m.ConsultaFgtsModule),
      }, 
      {
        path: 'gap/:codigo/esteirafgts',
        data: { breadcrumb: 'Esteira Fgts' },
        loadChildren: () =>
          import('./pages/GFgts/esteirafgts/esteirafgts.module').then((m) => m.EsteiraFgtsModule),
      }, 
      //SUPORTE
      {
        path: 'gap/:codigo/cofresenha',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Cofre de Senha' },
        loadChildren: () =>
          import('./pages/GSuporte/cofresenha/cofresenha.module').then(
            (m) => m.CofreSenhaModule
          ),
      },
      {
        path: 'gap/:codigo/statusproposta',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Status Proposta' },
        loadChildren: () =>
          import('./pages/GSuporte/statusproposta/statusproposta.module').then(
            (m) => m.StatusPropostaModule
          ),
      },
      {
        path: 'gap/:codigo/motivo',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Motivo' },
        loadChildren: () =>
          import('./pages/GSuporte/motivo/motivo.module').then(
            (m) => m.MotivoModule
          ),
      },
      {
        path: 'gap/:codigo/tipodocumento',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Tipos de Documento' },
        loadChildren: () =>
          import('./pages/GSuporte/arquivotipo/arquivotipo.module').then(
            (m) => m.ArquivoTipoModule
          ),
      },
      {
        path: 'gap/:codigo/conta',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Conta' },
        loadChildren: () =>
          import('./pages/GFinanceiro/conta/conta.module').then(
            (m) => m.ContaModule
          ),
      },
      {
        path: 'gap/:codigo/formapagamento',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Forma de Pagamento' },
        loadChildren: () =>
          import('./pages/GFinanceiro/formapagamento/formapagamento.module').then(
            (m) => m.FormaPagamentoModule
          ),
      },
      //IMPORTACAO
      {
        path: 'gap/:codigo/campo',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Campo' },
        loadChildren: () =>
          import('./pages/GImportacao/campo/campo.module').then((m) => m.CampoModule),
      },
      {
        path: 'gap/:codigo/layout',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Layout' },
        loadChildren: () =>
          import('./pages/GImportacao/layout/layout.module').then((m) => m.LayoutModule),
      },
      {
        path: 'gap/:codigo/statusapi',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Status' },
        loadChildren: () =>
          import('./pages/GImportacao/status_api/status_api.module').then(
            (m) => m.StatusApiModule
          ),
      },
      {
        path: 'gap/:codigo/comissaoapi',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Comissão' },
        loadChildren: () =>
          import('./pages/GImportacao/comissao/comissao.module').then(
            (m) => m.ComissaoModule
          ),
      },
      {
        path: 'gap/:codigo/convenioapi',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Convênio API' },
        loadChildren: () =>
          import('./pages/GImportacao/convenio_api/convenio_api.module').then(
            (m) => m.ConvenioApiModule
          ),
      },
      {
        path: 'gap/:codigo/formacontratoapi',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Forma de Contrato API' },
        loadChildren: () =>
          import('./pages/GImportacao/formadecontrato_api/formadecontrato_api.module').then(
            (m) => m.FormadecontratoApiModule
          ),
      },
      {
        path: 'gap/:codigo/finalidadeapi',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Finalidade' },
        loadChildren: () =>
          import('./pages/GImportacao/finalidade_api/finalidade_api.module').then(
            (m) => m.FinalidadeApiModule
          ),
      },
      {
        path: 'gap/:codigo/financeiraapi',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Banco' },
        loadChildren: () =>
          import('./pages/GImportacao/financeiracia_api/financeiracia_api.module').then(
            (m) => m.FinanceiraciaApiModule
          ),
      },
      {
        path: 'gap/:codigo/usuarioapi',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Usuário' },
        loadChildren: () =>
          import('./pages/GImportacao/usuario_api/usuario_api.module').then(
            (m) => m.UsuarioApiModule
          ),
      },
      {
        path: 'gap/:codigo/bancoapi',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Banco Api' },
        loadChildren: () =>
          import('./pages/GImportacao/bancoapi/bancoapi.module').then(
            (m) => m.BancoApiModule
          ),
      },
      {
        path: 'gap/:codigo/motivoapi',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Motivo API' },
        loadChildren: () =>
          import('./pages/GImportacao/motivoapi/motivoapi.module').then(
            (m) => m.MotivoApiModule
          ),
      },
      {
        path: 'gap/:codigo/importacao',
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Importação' },
        loadChildren: () =>
          import('./pages/importacao/importacao.module').then(
            (m) => m.ImportacaoModule
          ),
      },
      { path: 'perfil', 
        canActivate: [AuthGuardService],
        loadChildren: () => 
          import('./pages/user/user.module').then(m => m.UserModule), 
      },
    ],
  },
  { path: 'unauthorized', 
  loadChildren: () => 
    import('./pages/accessdenied/accessdenied.module').then(m => m.AccessdeniedModule), 
  },
  {
    path: 'login',
    data: { breadcrumb: 'login' },
    loadChildren: () =>
      import('./pages/auth/login/login.module').then(
        (m) => m.LoginModule
      ),
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'notfound',
    loadChildren: () =>
      import('./pages/notfound/notfound.module').then((m) => m.NotfoundModule),
  },
  { path: '**', redirectTo: '/notfound' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}