<div class="flex-container">
    <div class="layout-menu-container">
        <ul class="layout-menu">
            <ng-container *ngFor="let item of model; let i = index;">
                <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
                <li *ngIf="item.separator" class="menu-separator"></li>
            </ng-container>
        </ul>
    </div>

    <div class="layout-topbar" style="padding: 0px; margin: 0px;" *ngIf = '!MobileView() && menuMode()'>
        <div class="topbar-start">
            <button #menubutton type="button" class="topbar-menubutton p-link p-trigger" (click)="onMenuButtonClick()">
                <i class="pi pi-bars"></i>
            </button>

            <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
        </div>

        <div class="topbar-end" style="padding-right: 15px;">
            <ul class="topbar-menu">
                <li class="topbar-profile">
                    <button type="button" class="p-link" (click)="onProfileButtonClick()">
                        <img src="assets/layout/images/avatar.png" alt="Profile" />
                    </button>
                </li>
                <li class="ml-3">
                    <button pButton type="button" 
                        icon="pi pi-cog" 
                        class="p-button-text p-button-secondary p-button-rounded flex-shrink-0" 
                        (click)="onConfigButtonClick()"></button>
                </li>
                <li class="ml-3">
                    <button pButton type="button" 
                    icon="pi pi-sign-out" 
                    class="p-button-text p-button-secondary p-button-rounded flex-shrink-0" 
                    (click)="signOut()"></button>
                </li>
                
            </ul>
        </div>
    </div>
</div>

<style>
    .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.layout-menu-container {
    flex: 1;
}

.layout-topbar {
    display: flex;
    align-items: center;
}
</style>